export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const LANGUAGE = 'LANGUAGE';
export const WALLET = 'WALLET';
export const MM_RECEIVE_ACCOUNT = 'mm/RECEIVE_ACCOUNT';
export const MM_CHANGE_ACCOUNT = 'mm/CHANGE_ACCOUNT';
export const MM_LOGOUT = 'mm/LOGOUT';
export const BRTK_BALANCE = 'BRTK_BALANCE';
export const BRTK_STAKED = 'BRTK_STAKED';
export const LP_BALANCE = 'LP_BALANCE';
export const LP_STAKED = 'LP_STAKED';
