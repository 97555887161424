import React from "react";
import { Route, Switch } from "react-router-dom";

import WalletPopup from "components/WalletPopup.js";

import Home from "views/Home.js";
import Lp from "views/Lp.js";
import Trades from "views/Trades.js";
import NotFound from "views/NotFound.js";

class Base extends React.Component {
  render() {
    return (
      <>
        {/* <Header /> */}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/lp" component={Lp} />
          <Route path="/trades" component={Trades} />
          <Route component={NotFound} />
        </Switch>
        <WalletPopup />
        {/* <Footer /> */}
      </>
    );
  }
}

export default Base;
