import axios from 'axios';

const nomicsApiUrl = "https://api.nomics.com/v1/currencies/ticker"

export async function getTVLPrice() {

    const response = await axios.get(nomicsApiUrl, {
        params: {
            key: "c947ba8bcfd70bc8484a5d61e5d4169a99e18a96",
            ids: "BRTK",
            interval: "1h",
            convERT: "USD",
            perpage: "100",
            page: "1",
        }
    });

    return response.data[0]['price'];

};

export async function getBNBPrice() {
    const response = await axios.get("https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT");
    return response.data['price'];
}
