import React from "react";
import config from "../config";
import { connect } from "react-redux";
import { ethers, BigNumber } from "ethers";
import SidePanel from "./SidePanel.js";
import { Table } from "reactstrap";
import { getTVLPrice, getBNBPrice } from "service/price.js";
import { getTransactions } from "service/transactions.js";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import RedirectIcon from "../assets/img/redirect.png";

Number.prototype.formatMoney = function (c, d, t) {
  var n = this,
    c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d === undefined ? "." : d,
    t = t === undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
};

String.prototype.formatAddress = function (c, d, t) {
  var s = this;
  console.log(s);
  return s.substr(0, c) + d + s.substr(s.length - t, s.length);
};

const ROW_COUNT = 4;
class Trades extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isStatusOpen: false,
      isTokenOpen: false,
      status: "Staking",
      token: "Brtk",
      transactionData: [],
      stakings: [],
      unstakings: [],
      claims: [],
      currentPage: 0,
      bRTKPrice: 0,
      bnbPrice: 0,
      lpPrice: 0,
    };
  }

  fetchPrice = async () => {
    await Promise.all([getTVLPrice()])
      .then((resp) => {
        this.setState({
          bRTKPrice: resp[0],
        });
        console.log(resp[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchBNBPrice = async () => {
    await Promise.all([getBNBPrice()])
      .then((resp) => {
        this.setState({
          bnbPrice: resp[0],
        });
        console.log(resp[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getTotalAssetvalue = async () => {
    const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
    const bRTKContract = new ethers.Contract(
      config.brTokenAddress,
      config.brTokenABI,
      ethersProvider.getSigner()
    );
    const bRTKBalance = await bRTKContract.balanceOf(config.LPAddress);

    const wbnbContract = new ethers.Contract(
      config.wbnbAddress,
      config.erc20ABI,
      ethersProvider.getSigner()
    );
    console.log("+++++++++++++++++++++++++++++++++++++");
    const wbnbBalance = await wbnbContract.balanceOf(config.LPAddress);
    console.log("brtkbalance: ", bRTKBalance);
    console.log("wbnbBalance: ", wbnbBalance);

    const totalAssetValue =
      (bRTKBalance / Math.pow(10, 18)) * this.state.bRTKPrice +
      (wbnbBalance / Math.pow(10, 18)) * this.state.bnbPrice;
    console.log("totalAssetValue", totalAssetValue);

    const lpContract = new ethers.Contract(
      config.LPAddress,
      config.erc20ABI,
      ethersProvider.getSigner()
    );
    const lpSupply = (await lpContract.totalSupply()) / Math.pow(10, 18);
    console.log("lpSupply:", lpSupply);
    const lpPrice = totalAssetValue / lpSupply;
    console.log("brtkPrice:", this.state.bRTKPrice);
    console.log("lpPRice:", lpPrice);
    this.setState({
      lpPrice,
    });
  };

  getTransaction = async () => {
    await Promise.all([getTransactions()])
      .then((resp) => {
        console.log(resp[0]);
        const data = resp[0].reverse()
        this.setState({
          transactionData: data,
        });

        console.log(this.state.transactionData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChangeAction = (action) => {
    this.setState({
      status: action,
    });
  };

  onPrevious = (e) => {
    e.preventDefault();
    if (this.state.currentPage > 0) {
      this.setState({
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  onNext = (e) => {
    e.preventDefault();

    // const maxPage = this.state.transactionData.length % ROW_COUNT === 0 ? this.state.transactionData.length / ROW_COUNT - 1 : this.state.transactionData.length / ROW_COUNT;
    const maxPage = this.state.transactionData.length / ROW_COUNT - 1;
    console.log(maxPage);
    if (this.state.currentPage < maxPage) {
      this.setState({
        currentPage: this.state.currentPage + 1,
      });
    }
  };

  componentDidMount() {
    this.fetchPrice();
    this.fetchBNBPrice();
    this.getTotalAssetvalue();
    this.getTransaction();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(this.state);
    if (this.props.ethAddress !== prevProps.ethAddress) {
      this.fetchPrice();
      this.fetchBNBPrice();
      this.getTotalAssetvalue();
      this.getTransaction();
    }
  }

  toggleStatus = () => {
    this.setState({ isStatusOpen: !this.state.isStatusOpen });
  };

  toggleToken = () => {
    this.setState({ isTokenOpen: !this.state.isTokenOpen });
  };

  render() {
    return (
      <>
        <SidePanel />
        <main className="main">
          <section className="rightSection">
            <div className="container-fluid">
              <div className="row tradePanel">
                <div className="col-12">
                  <hr></hr>
                  <div className="d-flex boxHead">
                    <div className="boxHead_L">
                      <h2>
                        <span>Transactions</span>
                      </h2>
                    </div>
                  </div>
                  <hr></hr>
                  <Table borderless hover>
                    <thead>
                      <tr>
                        <th className="text-center">
                          <ButtonDropdown
                            className="actionCard"
                            isOpen={this.state.isStatusOpen}
                            toggle={this.toggleStatus}
                          >
                            <span>Actions</span>
                            <DropdownToggle caret>
                              {this.state.status}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => this.onChangeAction("Staking")}
                              >
                                Staked
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() => this.onChangeAction("Claim")}
                              >
                                Claimed
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() => this.onChangeAction("Unstaking")}
                              >
                                Unstake
                              </DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>
                        </th>
                        <th className="text-center">
                          <ButtonDropdown
                            className="actionCard2"
                            isOpen={this.state.isTokenOpen}
                            toggle={this.toggleToken}
                          >
                            <span>Actions</span>
                            <DropdownToggle caret>
                              {this.state.token}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => this.setState({ token: "Brtk" })}
                              >
                                Brtk
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() => this.setState({ token: "Lp" })}
                              >
                                Lp
                              </DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>
                        </th>
                        <th>Total value</th>
                        <th>Amount</th>
                        <th>Account</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.transactionData
                        .filter(
                          (data) =>
                            (!data.isLpToken && this.state.token === "Brtk") ||
                            (data.isLpToken && this.state.token === "Lp")
                        )
                        .filter((data) => data.type == this.state.status)
                        .slice(
                          this.state.currentPage * ROW_COUNT,
                          this.state.currentPage * ROW_COUNT + ROW_COUNT
                        )
                        .map((transaction, index) => {
                          return (
                            <tr key={index}>
                              <th></th>
                              <th></th>
                              <th>
                                {this.state.token === "Brtk"
                                  ? `$${(
                                      (parseInt(transaction.amount) /
                                        Math.pow(10, 18)) *
                                      this.state.bRTKPrice
                                    ).formatMoney(2, ".", ",")}`
                                  : `$${(
                                      (parseInt(transaction.amount) /
                                        Math.pow(10, 18)) *
                                      this.state.lpPrice
                                    ).formatMoney(2, ".", ",")}`}
                              </th>
                              <th>
                                {(
                                  parseInt(transaction.amount) /
                                  Math.pow(10, 18)
                                ).formatMoney(2, ".", ",")}
                              </th>
                              <th>
                                {transaction.sender.formatAddress(6, "...", 4)}
                                <a
                                  href={`https://bscscan.com/tx/${transaction.transactionHash}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img src="https://img.icons8.com/office/50/000000/link.png" />
                                </a>
                              </th>
                              <th>
                                {new Date(
                                  parseInt(transaction.time) * 1000
                                ).toString()}
                              </th>
                              {/* <th>6 mins ago <br></br> <span>(jul-22-2021 08:16:15 am +UTC)</span></th> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  <div className="row paginator">
                    <a href="#" onClick={(e) => this.onPrevious(e)}>
                      <h6 className="prev">Previous</h6>
                    </a>
                    <a href="#">
                      <h6
                        className="page"
                        onClick={() => {
                          this.setState({
                            currentPage: this.state.currentPage + 1,
                          });
                        }}
                      >
                        {this.state.currentPage + 1}
                      </h6>
                    </a>
                    <a href="#">
                      <h6
                        className="page"
                        onClick={() => {
                          this.setState({
                            currentPage: this.state.currentPage + 2,
                          });
                        }}
                      >
                        {this.state.currentPage + 2}
                      </h6>
                    </a>
                    <a href="#">
                      <h6
                        className="page"
                        onClick={() => {
                          this.setState({
                            currentPage: this.state.currentPage + 3,
                          });
                        }}
                      >
                        {this.state.currentPage + 3}
                      </h6>
                    </a>
                    <a href="#" onClick={(e) => this.onNext(e)}>
                      <h6 className="next">Next</h6>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ethAddress: state.ethAddress,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Trades);
