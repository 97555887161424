import React from "react";
import config from "../config";
import { connect } from "react-redux";
import { ethers, BigNumber } from "ethers";
import { getTVLPrice } from "service/price.js";
import SidePanel from "./SidePanel.js";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

Number.prototype.formatMoney = function (c, d, t) {
  var n = this,
    c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d === undefined ? "." : d,
    t = t === undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bRTKAmountToStake: 0,
      bRTKAmountToUnstake: 0,
      bRTKBalance: 0,
      bRTKStaked: 0,
      bRTKTotalEarned: 0,
      bRTKPendingAmount: 0,
      tvl: 0,
      totalSupply: 0,
      totalStaked: 0,
      totalStakers: 0,
      circulatingSupply: 0,
      bRTKPrice: 0,
      progressMessage: "",
      progressMessageState: "",
      isSpinnerShow: 0,
      txStatus: true
    };
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  setMaxToStakeBRtk = () => {
    this.setState({
      bRTKAmountToStake: this.state.bRTKBalance,
    });
  };

  setMaxToUnstakeBRtk = () => {
    console.log(this.state.bRTKStaked);
    this.setState({
      bRTKAmountToUnstake: this.state.bRTKStaked,
    });
  };

  setProgressMessage = (msg, msg1) => {
    this.setState({
      progressMessage: msg,
      progressMessageState: msg1,
    });
  };

  togglePopupProgress = () => {
    this.setState({ progressIsOpen: !this.state.progressIsOpen }, () => {
      document.body.classList.remove("modal-open");
    });
  };

  _checkApproval = async (amount, contract) => {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const bRTKContract = new ethers.Contract(
        config.brTokenAddress,
        config.brTokenABI,
        ethersProvider.getSigner()
      );
      let allowance = await bRTKContract.allowance(
        this.props.ethAddress,
        contract
      );

      let amountInWei = ethers.utils.parseEther(amount.toString());

      console.log(Number(allowance));
      console.log(Number(amountInWei));
      if (Number(allowance) <= Number(amountInWei)) {
        // const amountToApprove = BigNumber.from((Number(amountInWei)).toString());

        const approve = await bRTKContract.approve(
          contract,
          ethers.utils.parseEther(amount),
          { gasLimit: 200000 }
        );
        const txResult = await ethersProvider.waitForTransaction(approve.hash, 1);
        return txResult.status;
        
      }
    } catch (error) {
      
      if (error.message) {
      }
      return 2;
    }
  };

  _callStake = async (amount, callback) => {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const stakingContract = new ethers.Contract(
        config.proxyAddress,
        config.stakingABI,
        ethersProvider.getSigner()
      );

      let amountToSend = ethers.utils.parseEther(amount);

      const stake = await stakingContract.stake(amountToSend, false, {
        gasLimit: 200000,
      });
      const txResult = await ethersProvider.waitForTransaction(stake.hash, 1);
      return txResult.status;
      
    } catch (error) {
      
      console.log(error);
      if (error.message) {
      }
      return 2;
    }
  };

  _callUnstake = async (amount, callback) => {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const stakingContract = new ethers.Contract(
        config.proxyAddress,
        config.stakingABI,
        ethersProvider.getSigner()
      );

      let amountToUnStake = ethers.utils.parseEther(amount);
      console.log("Unstake");
      console.log(amountToUnStake);
      const unstake = await stakingContract.unstake(amountToUnStake, false, {
        gasLimit: 200000,
      });
      const txResult = await ethersProvider.waitForTransaction(unstake.hash, 1);
      return txResult.status;
    } catch (error) {
      console.log(error);
      if (error.message) {
      }
      return 2;
    }
  };

  _callClaim = async (amount, callback) => {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
      const stakingContract = new ethers.Contract(
        config.proxyAddress,
        config.stakingABI,
        ethersProvider.getSigner()
      );

      const claim = await stakingContract.claim(false, { gasLimit: 200000 });
      const txResult = await ethersProvider.waitForTransaction(claim.hash, 1);
      return txResult.status;
    } catch (error) {
      console.log(error);
      if (error.message) {
      }
      return 2;
    }
  };

  getStakingInfo = async () => {
    const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
    const bRTKContract = new ethers.Contract(
      config.brTokenAddress,
      config.brTokenABI,
      ethersProvider.getSigner()
    );
    const bRTKBalance = await bRTKContract.balanceOf(this.props.ethAddress);
    console.log("balance", bRTKBalance);

    const stakingContract = new ethers.Contract(
      config.proxyAddress,
      config.stakingABI,
      ethersProvider.getSigner()
    );
    let depositInfo = await stakingContract.userInfo(
      this.props.ethAddress,
      false
    );
    const bRTKStaked = depositInfo.amount;

    console.log("staked", bRTKStaked);

    const pending = await stakingContract.getPending(false);
    console.log("Pending: ", pending);

    const totalStaked = await stakingContract.totalStakingAmount(false);
    const tvl = ethers.utils.formatEther(totalStaked) * this.state.bRTKPrice;
    const totalSupply = await bRTKContract.totalSupply();
    const _totalStakers = await stakingContract.totalStakers(false);
    const circulatingSupply = await bRTKContract.totalSupply();
    this.setState({
      bRTKBalance: ethers.utils.formatEther(bRTKBalance),
      bRTKStaked: ethers.utils.formatEther(bRTKStaked),
      bRTKPendingAmount: ethers.utils.formatEther(pending),
      bRTKTotalEarned: ethers.utils.formatEther(depositInfo.claimed),
      totalStaked: ethers.utils.formatEther(totalStaked),
      totalSupply: ethers.utils.formatEther(totalSupply),
      totalStakers: parseInt(_totalStakers._hex, 16),
      circulatingSupply: ethers.utils.formatEther(circulatingSupply),
      tvl,
    });
  };

  fetchTVL = async () => {
    await Promise.all([getTVLPrice()])
      .then((resp) => {
        this.setState({
          bRTKPrice: resp[0],
        });
        console.log(resp[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    console.log("ETH address: ", this.props.ethAddress);
    if (this.props.ethAddress !== null) {
      this.getStakingInfo();
    }
    
    this.fetchTVL();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(this.state);
    if (this.props.ethAddress !== prevProps.ethAddress) {
      this.getStakingInfo();
      this.fetchTVL();
    }
  }

  onStakeBRtk = async () => {
    this.togglePopupProgress();
    this.setProgressMessage("Approve BRTK tokens", "");
    this.setState({ isSpinnerShow: true });
    this.setState({ txStatus: true });
    await this._checkApproval(
      this.state.bRTKAmountToStake,
      config.proxyAddress
    );

    this.setProgressMessage("Stake BRTK tokens", "");
    const res = await this._callStake(this.state.bRTKAmountToStake);
    this.getStakingInfo();
    this.setState({ isSpinnerShow: false });
    if (res === 1) {
      this.setProgressMessage("Staked BRTK Tokens Successfully!", "");
      this.setState({
        txStatus: true
      })
    } else if (res === 0) {
      this.setProgressMessage("Failed Staking BRTK Tokens!", "");
      this.setState({
        txStatus: false
      })
    } else if (res === 2) {
      if (this.state.bRTKAmountToStake === 0) {
        this.setProgressMessage("Please input amount to stake!", "");
      } else {
        this.setProgressMessage("Rejected Transaction!", "");
      }
      this.setState({
        txStatus: false
      })
    }
  };

  onUnstakeBRtk = async () => {
    this.togglePopupProgress();
    this.setProgressMessage("Unstake BRTK tokens", "");
    this.setState({ isSpinnerShow: true });
    this.setState({ txStatus: true });
    const res = await this._callUnstake(this.state.bRTKAmountToUnstake);
    this.getStakingInfo();
    this.setState({ isSpinnerShow: false });
    if (res === 1) {
      this.setProgressMessage("Unstaked BRTK Tokens Successfully!", "");
      this.setState({
        txStatus: true
      })
    } else if (res === 0) {
      this.setProgressMessage("Failed Unstaking BRTK Tokens!", "");
      this.setState({
        txStatus: false
      })
    } else if (res === 2) {
      if (this.state.bRTKAmountToUnstake === 0) {
        this.setProgressMessage("Please input amount to stake!", "");
      } else {
        this.setProgressMessage("Rejected Transaction!", "");
      }
      this.setState({
        txStatus: false
      })
    }
  };

  onClaim = async () => {
    this.togglePopupProgress();
    this.setProgressMessage("Claim Rewards", "");
    this.setState({ isSpinnerShow: true });
    this.setState({ txStatus: true });
    const res = await this._callClaim();
    this.getStakingInfo();
    this.setState({ isSpinnerShow: false });
    if (res === 1) {
      this.setProgressMessage("Claimed Rewards Successfully!", "");
      this.setState({
        txStatus: true
      })
    } else if (res === 0) {
      this.setProgressMessage("Failed Claimg Rewards!", "");
      this.setState({
        txStatus: false
      })
    } else if (res === 2) {
      this.setProgressMessage("Rejected Transaction!", "");
      this.setState({
        txStatus: false
      })
    }
  };

  render() {
    console.log("RENDER", this.state.bRTKBalance);
    return (
      <>
        <SidePanel />
        <main className="main">
          <section className="rightSection">
            <div className="container-fluid">
              <div className="row">
                <div className="col-4">
                  <div className="darkPanel">
                    <div className="d-flex boxHead">
                      <div className="boxHead_L">
                        <h6>
                          <span>BRTK</span>{" "}
                          <span className="navyBlue">Staking</span>{" "}
                          <span>TVL</span>
                        </h6>
                        {/* <p className="sub_T">Some data here</p> */}
                      </div>
                      <div className="boxHead_R text-right">
                        <i>
                          <img src={require("assets/img/ic_grow.svg")} alt="" />
                        </i>
                        <div className="price_ greenText">$ {(parseFloat(this.state.tvl)).formatMoney(2, '.', ',')}</div>
                      </div>
                    </div>
                    {/* <div className="progressBarStatus">
                      <div className="progress_head">
                        <span className="pro_title">Yearly</span>
                        <span className="pro_percent greenText">70%</span>
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar blueBar"
                          style={{ width: "70%" }}
                        ></div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-4">
                  <div className="darkPanel">
                    <div className="d-flex boxHead">
                      <div className="boxHead_L">
                        <h6>
                          <span>BRTK</span>{" "}
                          <span className="navyBlue">Staking</span>{" "}
                          <span>Total Supply</span>
                        </h6>
                        
                        {/* <p className="sub_T">Some data here</p> */}
                      </div>
                      <div className="boxHead_R text-right">
                        <i>
                          <img
                            src={require("assets/img/ic_grow_down.svg")}
                            alt=""
                          />
                        </i>
                        <div className="price_ redText">{(parseFloat(this.state.totalSupply)).formatMoney(2, '.', ',')}</div>
                      </div>
                    </div>
                    {/* <div className="progressBarStatus">
                      <div className="progress_head">
                        <span className="pro_title">Monthly</span>
                        <span className="pro_percent redDarkText">6%</span>
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          style={{ width: "30%" }}
                        ></div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-4">
                  <div className="darkPanel">
                    <div className="d-flex boxHead">
                      <div className="boxHead_L">
                        <h6>
                          <span>BRTK</span>{" "}
                          <span className="navyBlue">Staking</span>{" "}
                          <span>Total Staked</span>
                        </h6>
                        {/* <p className="sub_T">Some data here</p> */}
                      </div>
                      <div className="boxHead_R text-right">
                        <i>
                          <img
                            src={require("assets/img/ic_grow_down.svg")}
                            alt=""
                          />
                        </i>
                        <div className="price_ redText">{(parseFloat(this.state.totalStaked)).formatMoney(2, '.', ',')}</div>
                      </div>
                    </div>
                    {/* <div className="progressBarStatus">
                      <div className="progress_head">
                        <span className="pro_title">Daily</span>
                        <span className="pro_percent redDarkText">0,2%</span>
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar blueBar"
                          style={{ width: "10%" }}
                        ></div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="darkPanel aprPanel">
                    <h6>STAKE BRTK & EARN (12.68% APY) Min 100 $BRTK</h6>
                    <p className="text_light">Minimum lock time 7 days</p>
                    <div className="wrap_400">
                      <div className="row">
                        <div className="col-6 btn_obj">
                          <button
                            type="button"
                            className="btn_ btn_full"
                            onClick={this.onStakeBRtk}
                          >
                            Stake
                          </button>
                        </div>
                        <div className="col-6 btn_obj">
                          <div className="inputBtnCol">
                            <input
                              type="number"
                              name="bRTKAmountToStake"
                              value={this.state.bRTKAmountToStake}
                              onChange={(e) => this.handleInputChange(e)}
                            />
                            <button
                              type="button"
                              className="btn_max"
                              onClick={this.setMaxToStakeBRtk}
                            >
                              Max
                            </button>
                          </div>
                        </div>
                        <div className="col-12 btn_obj">
                          <div className="feeText text-center">
                            <span className="percentColor">
                              fees are applied when unstaking tokens
                            </span>{" "}
                          </div>
                        </div>
                        <div className="col-6 btn_obj">
                          <button
                            type="button"
                            className="btn_ btn_full btn_yellow"
                            onClick={this.onUnstakeBRtk}
                          >
                            Unstake
                          </button>
                        </div>
                        <div className="col-6 btn_obj">
                          <div className="inputBtnCol">
                            <input
                              type="number"
                              name="bRTKAmountToUnstake"
                              value={this.state.bRTKAmountToUnstake}
                              onChange={(e) => this.handleInputChange(e)}
                            />
                            <button
                              type="button"
                              className="btn_max"
                              onClick={this.setMaxToUnstakeBRtk}
                            >
                              Max
                            </button>
                          </div>
                        </div>
                        <div className="col-12 btn_obj">
                          <div className="feeText text-center">
                            0<span className="percentColor">%</span> fee for
                            unstaking
                          </div>
                        </div>
                        <div className="col-6 btn_obj">
                          <button
                            type="button"
                            className="btn_ btn_full btn_yellow"
                            onClick={this.onClaim}
                          >
                            Claim
                          </button>
                        </div>
                        <div className="col-6 btn_obj">
                          <div className="claimCoin">
                          {(parseFloat(this.state.bRTKPendingAmount)).formatMoney(4, '.', ',')} <span>BRTK</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row tipsRow">
                      <div className="col-10 offset-1 tipsCol">
                        <h5 className="text-center">
                          Tips regarding your transactions:
                        </h5>
                        <ol className="tipsList" type="I">
                          <li>
                          - To stake BRToken you'll first need a Binance Smart Chain supported wallet.
                          </li>
                          <li>
                           - connect your wallet to the Dapp, input the amount of tokens, and click stake to begin the process. 
                          </li>

                          {/* <li>
                          Note: do not close the pop-up during blockchain Process.
                          </li> */}
                          <li>
                          - To complete the staking process, approve and confirm when prompted by your connected wallet.
                          </li>
                          <li>
                          *  <a href="https://nomics.com" className="small-text" target="_blank" rel="noreferrer">Crypto Market Cap & Pricing Data Provided By Nomics</a>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="darkPanel">
                    <ul className="walletList walletList_1st">
                      <li>
                        <label className="label_">Price </label>
                        <span className="amt_ textBold">${this.state.bRTKPrice}</span>
                      </li>
                      <li>
                      <a href="https://bscscan.com/address/0x99c2acd4c12d98ee5002e8b8b5b25302650e420f" target="_blank" className="label_" rel="noreferrer">
                          Contract <i className="fas fa-chevron-right"></i>
                        </a>
                      </li>
                    </ul>
                    <ul className="walletList textBold">
                      <li>
                        <label className="label_ blueText">My balance</label>
                        <span className="amt_">
                        {(parseFloat(this.state.bRTKBalance)).formatMoney(2, '.', ',')}{" "}
                          <span className="blueText">BRTK</span>
                        </span>
                      </li>
                      <li>
                        <label className="label_ blueText">Staked </label>
                        <span className="amt_">
                        {(parseFloat(this.state.bRTKStaked)).formatMoney(2, '.', ',')}{" "}
                          <span className="blueText">BRTK</span>
                        </span>
                      </li>
                      <li>
                        <label className="label_ blueText">Total Earned</label>
                        <span className="amt_">
                        {(parseFloat(this.state.bRTKTotalEarned)).formatMoney(2, '.', ',')}{" "}
                          <span className="blueText">BRTK</span>
                        </span>
                      </li>
                      <li>
                        <label className="label_ blueText">Pending </label>
                        <span className="amt_">
                        {(parseFloat(this.state.bRTKPendingAmount)).formatMoney(4, '.', ',')}{" "}
                          <span className="blueText">BRTK</span>
                        </span>
                      </li>
                    </ul>
                    <div className="viewHistory mb-3">
                      View Transaction History on Bscscan.com{" "}
                      <img src={require("assets/img/ic_view.svg")} alt="" />
                    </div>

                    <ul className="walletList text_light mb-5">
                      <li>
                        <label className="label_">TVL</label>
                        <span className="amt_">
                        {(parseFloat(this.state.tvl)).formatMoney(2, '.', ',')} <span className="ml-2">USD</span>
                        </span>
                      </li>
                      <li>
                        <label className="label_">Total Supply</label>
                        <span className="amt_">
                          {(parseFloat(this.state.totalSupply)).formatMoney(2, '.', ',')}
                          <span className="ml-2">BRTK</span>
                        </span>
                      </li>
                      <li>
                        <label className="label_">Total Staked</label>
                        <span className="amt_">
                        {(parseFloat(this.state.totalStaked)).formatMoney(2, '.', ',')}
                          <span className="ml-2">BRTK</span>
                        </span>
                      </li>
                      <li>
                        <label className="label_">Total Stakers</label>
                        <span className="amt_">{this.state.totalStakers}</span>
                      </li>
                      <li>
                        <label className="label_">Circulating Supply</label>
                        <span className="amt_">
                        {(parseFloat(this.state.circulatingSupply)).formatMoney(2, '.', ',')}
                          <span className="ml-2">BRTK</span>
                        </span>
                      </li>
                    </ul>

                    <div className="row walletFooter">
                      <div className="col-12 text-right">
                        <a href="/" className="detailBtn_">
                          Details <i className="fas fa-chevron-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Modal
            isOpen={this.state.progressIsOpen}
            toggle={this.togglePopupProgress}
            size="md"
            className="progress-popup"
          >
            <ModalHeader
              toggle={this.togglePopupProgress}
              close={
                <button className="close" onClick={this.togglePopupProgress}>
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30 10L10 30"
                      stroke="#798A9E"
                      strokeWidth="3.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10 10L30 30"
                      stroke="#798A9E"
                      strokeWidth="3.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              }
            >
              Blockchain Process
            </ModalHeader>
            <ModalBody>
              <div className={this.state.txStatus ? "progress-message" : "progress-message-error"}>
                {this.state.progressMessage}{" "}
                {this.state.isSpinnerShow ? (
                  <Spinner
                    className="spinner"
                    animation="border"
                    variant="secondary"
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="progress-state">
                {this.state.progressMessageState}
              </div>
            </ModalBody>
          </Modal>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ethAddress: state.ethAddress,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
