import * as actionTypes from './actions';
import config from './../config';

const initialState = {
    ...config,
    isFullScreen: false,
    ethAddress: null,
    bRTKBalance: 0,
    bRTKStaked: 0,
    lpBalance: 0,
    lpStaked: 0,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FULL_SCREEN :
            return {
                ...state,
                isFullScreen: !state.isFullScreen
            };
        case actionTypes.FULL_SCREEN_EXIT:
            return {
                ...state,
                isFullScreen: false
            };
        case actionTypes.LANGUAGE:
            return {
                ...state,
                lang: action.lang
            };
        case actionTypes.MM_RECEIVE_ACCOUNT:
            return {
                ...state,
                ethAddress: action.address
            };

        case actionTypes.BRTK_BALANCE:
            return {
                ...state,
                bRTKBalance: action.bRTKBalance
            };

        case actionTypes.BRTK_STAKED:
            return {
                ...state,
                bRTKStaked: action.bRTKStaked
            };

        case actionTypes.LP_BALANCE:
            return {
                ...state,
                lpBalance: action.lpBalance
            };

        case actionTypes.LP_STAKED:
            return {
                ...state,
                lpStaked: action.lpStaked
            };
        
        case actionTypes.MM_CHANGE_ACCOUNT:
            return {
                ...state,
                ethAddress: action.address
            };
        case actionTypes.MM_LOGOUT:
            return {
                ...state,
                ethAddress: null
            }
        default:
            return state;
    }
};

export default reducer;