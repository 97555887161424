import React from "react";
import { Link } from "react-router-dom";

import Image from "../assets/img/logo.png";
import Menu from "../assets/img/menu.png";
import Chart from "../assets/img/Chart.png";
import Chat from "../assets/img/Chat.png";
import User from "../assets/img/User.png";
import Graph from "../assets/img/graph.png";
import Cancel from "../assets/img/fi_x.png";

class SidePanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: true
        }
        this.toggleMenu = this.toggleMenu.bind(this)
    }

    toggleMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu
        })
    }

    render() {
        return (
            <main className="main">
                <section id="mySidepanel" className="sidepanel">
                    {
                        this.state.showMenu ? (
                            <div className="m-4">
                                <div className="panelLogoCol">
                                    <a href="/"><img src={Image} alt="" /></a>
                                </div>

                                <div className="panelMenuCol">
                                    <a onClick={this.toggleMenu}><img src={Menu} alt="" /></a>
                                </div>
                                <div className="sidepanelMobCol">
                                    <div className="panelSideLogo">
                                        <a onClick={this.toggleMenu}><img src={Image} alt="" /></a>
                                    </div>
                                    {/* <Link to="/" className="panelLinks"><img src={Graph} alt="" /><span> Dashboard</span></Link> */}
                                    {/* <Link to="/lp" className="panelLinks"><img src={require("assets/img/ic_dashboard.svg")} alt="" /><span>LP Dashboard</span></Link> */}
                                    <Link to="/" className="panelLinks"><i><img src={Graph} alt=""></img></i> BR Dashboard</Link>
                                    <Link to="/lp" className="panelLinks"><i><img src={Graph} alt=""></img></i> LP Dashboard</Link>
                                    {/* <Link to="/" className="panelLinks"><i><img src={Chart} alt=""></img></i> Overview</Link> */}
                                    <a href="https://t.me/BRToken_NEWS" target="_blank" className="panelLinks" rel="noreferrer"><i><img src={Chat} alt=""></img></i> News & Updates <span className="notificationPil"></span></a>
                                    <Link to="/trades" className="panelLinks"><i><img src={User} alt=""></img></i> Transactions</Link>
                                </div>


                            </div>
                        ) : (
                            <div className="sidepanelCol">
                                <div className="panelLogoCol">
                                    <a href="/"><img src={Image} alt=""/></a>
                                </div>

                                <div className="panelMenuCol">
                                    <a onClick={this.toggleMenu}><img src={Cancel} alt="" /></a>
                                </div>

                                <div className="panelSideLogo">
                                    <a onClick={this.toggleMenu}><img src={Image} alt="" /></a>
                                </div>
                                {/* <Link to="/" className="panelLinks"><img src={Graph} alt="" /><span> Dashboard</span></Link> */}
                                {/* <Link to="/lp" className="panelLinks"><img src={require("assets/img/ic_dashboard.svg")} alt="" /><span>LP Dashboard</span></Link> */}
                                <Link to="/" className="panelLinks"><i><img src={Graph} alt=""></img></i> BR Dashboard</Link>
                                <Link to="/lp" className="panelLinks"><i><img src={Graph} alt=""></img></i> LP Dashboard</Link>
                                {/* <Link to="/" className="panelLinks"><i><img src={Chart} alt=""></img></i> Overview</Link> */}
                                <Link to="/" className="panelLinks"><i><img src={Chat} alt=""></img></i> News Updates <span className="notificationPil">5</span></Link>
                                <Link to="/trades" className="panelLinks"><i><img src={User} alt=""></img></i> Trades</Link>
                            </div>
                        )
                    }

                    {/* <a href="#" className="active"><i><img src={require("assets/img/ic_dashboard.svg")}/></i> Dashboard</a> */}
                </section>
            </main>
        )
    }
}

export default SidePanel;
