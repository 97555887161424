import React from "react";
import { connect } from 'react-redux'
import { ethers, BigNumber } from 'ethers';
import SidePanel from "./SidePanel.js";

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <>
                <SidePanel />
                <main className="main">
                    <section className="rightSection">
                        <div className="container-fluid">
                            <div className="row errorPanel">
                                <div className="col-12">
                                    <hr></hr>
                                    <div className="d-flex">
                                        <div>
                                            <h2><span>Oops... Page Not Found!</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </main>
            </>
        )
    }
}


export default NotFound
